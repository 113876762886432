$(function() {
  // Открытие модальных окон
  $('.js-show-modal').click(function() {
    $('.modal_' + $(this).data('modal')).removeClass('invisible');
  });

  // Скрытие модальных окон
  $('.js-hide-modal').click(function() {
    $(this).parents('.modal').addClass('invisible');
  });

  // Сортировка в результатах поиска
  $('.js-sort').click(function() {
    var $this = $(this);

    if ($this.hasClass('sort_unsorted')) {
      $this.removeClass('sort_unsorted').addClass('sort_asc');
      return;
    }
    if ($this.hasClass('sort_asc')) {
      $this.removeClass('sort_asc').addClass('sort_desc');
      return;
    }
    if ($this.hasClass('sort_desc')) {
      $this.removeClass('sort_desc').addClass('sort_unsorted');
      return;
    }
  });

  // Галерея

  // Увеличение
  $('.zoom').zoom();

  // Переключение слайдов
  function selectSlide(index) {
    if (index < 0 || index > $('.gallery__slide').length - 1) {
      return;
    }

    var currentSlide = $('.gallery__slide[active]');
    var nextSlide = $('.gallery__slide').get(index);

    $(currentSlide).removeAttr('active');
    $(nextSlide).attr('active', true);

    if ($(nextSlide).attr('video') && !$(currentSlide).attr('video')) {
        $('.zoom').trigger('zoom.destroy');
    }

    if (!$(nextSlide).attr('video') && $(currentSlide).attr('video')) {
        $('.zoom').zoom({url: $(nextSlide).find('img').attr('src')});

    }

      if (!$(nextSlide).attr('video')) {
          $('.zoomImg').attr('src', $(nextSlide).find('img').attr('src'));
      }

      selectPreview(index);
  }

  function selectPreview(index) {
    if (index < 0 || index > $('.gallery__preview').length - 1) {
      return;
    }

    var currentPreview = $('.gallery__preview[active]');
    var nextPreview = $('.gallery__preview').get(index);

    $(nextPreview).attr('active', true);
    $(currentPreview).removeAttr('active');

    var currentPosition = $('.gallery__preview-wrap').scrollLeft();
    var previewWidth = $('.gallery__preview').width();
    var nextPreviewLeft = $(nextPreview).position().left;
    var nextPreviewRight = $(nextPreview).position().left + previewWidth;
    var arrowWidth = $('.gallery__preview-button').width();
    var galleryWidth = $('.gallery__preview-wrap').width();
    var visibleLeftPosition = currentPosition + arrowWidth;
    var visibleRightPosition = currentPosition + galleryWidth - arrowWidth;

    if (nextPreviewLeft < visibleLeftPosition) {
      $('.gallery__preview-wrap').get(0).scrollTo({
        left: nextPreviewLeft - arrowWidth,
        behavior: 'smooth',
      });
    }

    if (nextPreviewRight > visibleRightPosition) {
      $('.gallery__preview-wrap').get(0).scrollTo({
        left: nextPreviewLeft - galleryWidth + arrowWidth + previewWidth,
        behavior: 'smooth',
      });
    }
  }

  $('.js-slide-this').click(function() {
    var currentSlideIndex = $(this).index();

    selectSlide(currentSlideIndex);
  });

  $('.js-slide-prev').click(function() {
    var currentSlideIndex = $('.gallery__slide[active]').index();

    selectSlide(currentSlideIndex - 1);
  });

  $('.js-slide-next').click(function() {
    var currentSlideIndex = $('.gallery__slide[active]').index();

    selectSlide(currentSlideIndex + 1);
  });

  $('.js-preview-prev').click(function() {
    var previewWidth = $('.gallery__preview').width();
    var currentPosition = $('.gallery__preview-wrap').scrollLeft();
    var nextPosition = currentPosition - previewWidth;

    $('.gallery__preview-wrap').get(0).scrollTo({
      left: nextPosition,
      behavior: 'smooth',
    });
  });

  $('.js-preview-next').click(function() {
    var previewWidth = $('.gallery__preview').width();
    var currentPosition = $('.gallery__preview-wrap').scrollLeft();
    var nextPosition = currentPosition + previewWidth;

    $('.gallery__preview-wrap').get(0).scrollTo({
      left: nextPosition,
      behavior: 'smooth',
    });
  });

  $('.gallery__preview-wrap').scroll(function() {
    var scrollPosition = $(this).scrollLeft();
    var arrowWidth = $('.gallery__preview-button').width();
    var previewWidth = $('.gallery__preview').width();
    var previewCount = $('.gallery__preview').length;
    var totalWidth = previewWidth * previewCount + arrowWidth * 2;

    var minPosition = 0;
    var maxPosition = Math.floor(totalWidth - $('.gallery__preview-wrap').width());

    if (scrollPosition === minPosition) {
      $('.gallery__preview-button_prev').attr('disabled', true);
    }

    if (scrollPosition > minPosition) {
      $('.gallery__preview-button_prev').removeAttr('disabled');
    }

    if (scrollPosition === maxPosition) {
      $('.gallery__preview-button_next').attr('disabled', true);
    }

    if (scrollPosition < maxPosition) {
      $('.gallery__preview-button_next').removeAttr('disabled');
    }
  });

  // Добавление товаров в корзину
  var $cartItemsCount = $('.js-cart-item-count');
  var $addToCartButton = $('.js-add-to-cart');
  var $requestPriceButton = $('.js-request-price-cart');

  $addToCartButton.click(function(event) {
    event.preventDefault();

    var productId = $(this).data('product-id');

    addToCart(productId, 'buy', this);
    animateItemIcon($addToCartButton);
  });

  $requestPriceButton.click(function(event) {
      event.preventDefault();

      var productId = $(this).data('product-id');

      addToCart(productId, 'request_price', this);
      animateItemIcon($requestPriceButton);
  });

  // Обновление количества товаров в корзине
  function setQuantity(quantity) {
    $.ajax({
      method: 'GET',
      url: '/cart/count',
    })
    .done(function(msg) {
      if (msg.count_products) {
        $cartItemsCount.text(msg.count_products);
      }
    });
  };

  function animateItemIcon($button) {
    var $startEl = $button.get(0);
    var $finalEl = $cartItemsCount.get(0);
    var startLeft = $startEl.getBoundingClientRect().left - 50;
    var startTop = $startEl.getBoundingClientRect().top;
    var finalLeft = $finalEl.getBoundingClientRect().left;
    var finalTop = $finalEl.getBoundingClientRect().top;

    var itemIcon = document.createElement('DIV');
    itemIcon.classList.add('item-icon', 'js-add-animation');
    document.body.appendChild(itemIcon);
    var $itemIcon = $(itemIcon);

    $itemIcon.css({
      left: startLeft,
      top: startTop,
      opacity: 0.75,
    }).addClass('ready');

    $itemIcon.get(0).offsetLeft; // Force reflow

    $itemIcon.css({
      left: finalLeft,
      top: finalTop,
      opacity: 0,
    });

    setTimeout(function() {
      $itemIcon.remove();
    }, 1000);
  }

  function addToCart(productId, type, obj) {

    var $inputQ = $('#quantity-' + productId);
    var itemsCount = $inputQ.val();

    $.ajax({
      method: 'POST',
      url: '/cart/product/add',
      data: {
        'productId': productId,
        'count': itemsCount,
        'type': type,
      },
    }).done(function() {
      setQuantity();
      $(obj).effect('transfer', { to: $cartItemsCount.eq(0) }, 700);
      $cartItemsCount.effect('bounce', { times:4 }, 500);
    });
  };
});